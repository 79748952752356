const typography = {
    body: {
        fontFamily: "Montserrat-Black",
        lineHeight: 1.1,
        fontSize: 16,
    },
    header1: {
        fontFamily: "Montserrat-Regular",
        lineHeight: 2,
        fontSize: 50,
    },
    myFont: {
        fontFamily: "My-Font",
        lineHeight: 1.2,
        fontSize: 24,
    },
    title1: {
        fontSize: 40,
        fontFamily: "poppins-semibold",
    },
    title2: {
        fontSize: 32,
        fontFamily: "poppins-regular",
    },
    title3: {
        fontFamily: "poppins-semibold",
        fontSize: 28,
    },
    title4: {
        fontFamily: "poppins-medium",
        fontSize: 21,
    },
    title5: {
        fontFamily: "poppins-medium",
        fontSize: 16,
    },
    title6: {
        fontFamily: "poppins-regular",
        fontSize: 16,
    },
    title7: {
        fontFamily: "poppins-semibold",
        fontSize: 21,
    },
    title8: {
        fontSize: 32,
        fontFamily: "poppins-semibold",
    },
    text1: {
        fontFamily: "poppins-regular",
        lineHeight: "4rem",
        fontSize: 21,
    },
    text2: {
        fontFamily: "poppins-semibold",
        fontSize: 18,
    },
    text3: {
        fontFamily: "poppins-regular",
        lineHeight: "3rem",
        fontSize: 16,
    },
    text4: {
        fontFamily: "poppins-regular",
        fontSize: 18,
    },
};
const colors = {
    primary: "#f5df4e",
    secondary: "#141414",
    black: "#f5f5f5",
    text: "#f5f5f5",
    button: "#252b33",
    gray: "#E8E9E9",
    gray100: "#6C757D",
    gray200: "#EFEFEF",
};
const dark = {
    key: "dark",
    typography,
    colors,
};
export default dark;
