export const MENU = [
  {
    key: "home",
    path: "#",
  },
  {
    key: "about",
    path: "#about",
  },
  {
    key: "resume",
    path: "#resume",
  },
  {
    key: "portfolio",
    path: "#portfolio",
  },
  {
    key: "contact",
    path: "#contact",
  },
];
